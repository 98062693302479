<template>
  <footer class="era-footer">
    <div class="era-footer-container flex-row">
      <div class="flex-1">
        <v-img
          width="1.6rem"
          src="@/assets/images/logo-header.png"
          class="era-logo-footer"
        >
        </v-img>
        <div class="era-footer-phone">联系电话：19901851908</div>
        <div class="era-footer-email">联系邮箱：enquiry@miqroera.com</div>
        <div class="era-footer-icon">
          <div class="icon-item">
            <div class="top">
              <v-img
                class="imgOne"
                @mouseenter="imgOneShow = true"
                @mouseleave="imgOneShow = false"
                width="0.4rem"
                src="@/assets/images/weixinicon.png"
                lazy-src="@/assets/images/weixinicon.png"
              ></v-img>
            </div>
            <div class="btn">
              <v-img
                v-show="imgOneShow"
                class="imgTwo"
                width="1rem"
                src="@/assets/images/weixinnew.png"
                lazy-src="@/assets/images/weixinnew.png"
              ></v-img>
            </div>
          </div>
          <div class="icon-item">
            <div class="top">
              <v-img
                class="imgOne"
                @mouseenter="imgTwoShow = true"
                @mouseleave="imgTwoShow = false"
                width="0.4rem"
                src="@/assets/images/weiboicon.png"
                lazy-src="@/assets/images/weiboicon.png"
              ></v-img>
            </div>
            <div class="btn">
              <v-img
                class="imgTwo"
                v-show="imgTwoShow"
                width="1rem"
                src="@/assets/images/weibo.png"
                lazy-src="@/assets/images/weibo.png"
              ></v-img>
            </div>
          </div>
          <div class="icon-item">
            <div class="top">
              <v-img
                class="imgOne"
                @mouseenter="imgThireShow = true"
                @mouseleave="imgThireShow = false"
                width="0.4rem"
                src="@/assets/images/zhihuicon.png"
                lazy-src="@/assets/images/zhihuicon.png"
              ></v-img>
            </div>
            <div class="btn">
              <v-img
                class="imgTwo"
                v-show="imgThireShow"
                width="1rem"
                src="@/assets/images/zhihunew.png"
                lazy-src="@/assets/images/zhihunew.png"
              ></v-img>
            </div>
          </div>
        </div>
        <!-- <div class="era-footer-rights">
          © 2022 MiQro Era. All rights reserved.
        </div>
        <div class="era-footer-policy" style="cursor: pointer;" @click="onLink('privacyPolicy','/privacy-policy','')">Privacy Policy</div>
        <div class="era-footer-policy"><a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer" style="text-decoration: none;color: #666;">沪ICP备2022025791号-1</a></div> -->
      </div>
      <div
        class="era-footer-classify"
        v-for="(classify, index) in footerList"
        :key="index"
      >
        <div class="era-footer-title">{{ classify.title }}</div>
        <div
          class="era-footer-content"
          v-for="(item, key) in classify.content"
          :key="key"
          @click="onLink(item.name, item.path, item.hash)"
        >
          {{ item.text }}
        </div>
      </div>

      <!-- <div
        class="era-footer-classify"
      >
        <div class="era-footer-title">关注我们</div>
        <div class="flex-row">
          <div class="era-footer-img-box">
            <div class="era-footer-img">
              <v-img
                class="img1"
                width="0.4rem"
                src="@/assets/images/weixin1.png"
              >
              </v-img>
              <v-img
                class="img2"
                width="0.4rem"
                src="@/assets/images/weixin2.png"
              >
              </v-img>
              <v-img
                class="img3"
                width="1.01rem"
                src="@/assets/images/weixin.png"
                @click="previewImg('weixin')"
              >
              </v-img>
            </div>
          </div>
          <div class="era-footer-img-box">
            <div class="era-footer-img">
              <v-img
                class="img1"
                width="0.4rem"
                src="@/assets/images/zhihu1.png"
              >
              </v-img>
              <v-img
                class="img2"
                width="0.4rem"
                src="@/assets/images/zhihu2.png"
              >
              </v-img>
              <v-img
                class="img3"
                width="1.01rem"
                src="@/assets/images/zhihu.png"
                @click="previewImg('zhihu')"
              >
              </v-img>
            </div>
          </div>
          <div class="era-footer-img-box">
            <div class="era-footer-img">
              <v-img
                class="img1"
                width="0.4rem"
                src="@/assets/images/bilibili1.png"
              >
              </v-img>
              <v-img
                class="img2"
                width="0.4rem"
                src="@/assets/images/bilibili2.png"
              >
              </v-img>
              <v-img
                class="img3"
                width="1.01rem"
                src="@/assets/images/bilibili.png"
                @click="previewImg('bilibili')"
              >
              </v-img>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div class="era-footer-others">
      <span class="era-footer-rights">
        © 2024 MiQro Era. All rights reserved.
      </span>
      <span
        class="era-footer-policy"
        style="cursor: pointer"
        @click="onLink('privacyPolicy', '/privacy-policy', '')"
        >隐私政策.</span
      >
      <span class="era-footer-policy"
        ><a
          href="https://beian.miit.gov.cn/"
          target="_blank"
          rel="noopener noreferrer"
          style="text-decoration: none; color: #666666"
          >皖ICP备2024033432号-1</a
        ></span
      >
    </div>

    <!-- <v-dialog v-model="showImgDialog" max-width="400">
      <v-card>
        <v-img
          src="@/assets/images/weixin.png"
          v-show="previewImage == 'weixin'"
        ></v-img>
        <v-img
          src="@/assets/images/zhihu.png"
          v-show="previewImage == 'zhihu'"
        ></v-img>
        <v-img
          src="@/assets/images/bilibili.png"
          v-show="previewImage == 'bilibili'"
        ></v-img>
      </v-card>
    </v-dialog> -->
  </footer>
</template>

<script>
import store from "@/store";
import { getNews } from "@/api/index";
export default {
  name: "PageFooter",
  data: () => ({
    imgOneShow: false,
    imgTwoShow: false,
    imgThireShow: false,
    /**
     * 底部列表
     */
    queryParams: {
      pageNum: 1,
      pageSize: 6,
    },
    footerList: [
      {
        title: "技术",
        content: [
          {
            text: "量子计算",
            name: "Home",
            path: "/home",
            hash: "#",
          },
          {
            text: "最大割-稳定子算法",
            name: "Home",
            path: "/home",
            hash: "#",
          },
          {
            text: "稳定子-自研优化算法",
            name: "Home",
            path: "/home",
            hash: "#",
          },
        ],
      },
      {
        title: "产品",
        content: [
          // {
          //   text: "小分子药",
          //   name: "Home",
          //   path: "/home",
          //   hash: "#home",
          // },
          {
            text: "开算平台",
            name: "Home",
            path: "/home",
            hash: "#catalytic",
          },
          {
            text: "量子模拟器",
            name: "Home",
            path: "/home",
            hash: "#catalytic",
          },
          {
            text: "药物虚拟筛选",
            name: "Home",
            path: "/home",
            hash: "#preparations",
          },
          {
            text: "量子化学应用",
            name: "Home",
            path: "/home",
            hash: "#",
          },
        ],
      },
      {
        title: "新闻",
        content: [
          {
            text: "新闻快讯",
            name: "News",
            path: "/news",
            hash: "1",
          },
          {
            text: "技术更新",
            name: "News",
            path: "/news",
            hash: "2",
          },
          {
            text: "合作事项",
            name: "News",
            path: "/news",
            hash: "3",
          },
        ],
      },
      {
        title: "关于我们",
        content: [
          {
            text: "加入我们",
            name: "Home",
            path: "/home",
            hash: "#joinUs",
          },
          {
            text: "联系我们",
            name: "contactUs",
            path: "/contact-us",
            hash: "",
          },
        ],
      },
    ],
    /**
     * 是否显示图片对话框
     */
    showImgDialog: false,
    /**
     * 预览的图片
     */
    previewImage: "",
  }),
  methods: {
    onImageOneHover() {
      console.log("xxxss");
    },
    /**
     * 点击跳转
     */
    async getNewsLists(data) {
      let params = this.queryParams;
      params.newsType = parseInt(data);
      let res = await getNews(params);
      if (res.code == 200) {
        this.$store.commit("news/setNewList", res);
      }
    },
    onLink(name, path, hash) {
      if (this.$route.path == path) {
        if (hash) {
          if (name === "News") {
            this.getNewsLists(hash);
          } else {
            this.$vuetify.goTo(hash, { offset: 156 });
          }
        } else {
          window.location.reload();
        }
      } else {
        if (name) {
          if (hash) {
            this.$router.push({
              name: name,
              params: {
                hash: hash,
              },
            });
          } else {
            this.$router.push({
              name: name,
            });
          }
        }
      }
    },
    /**
     * 预览图片
     */
    previewImg(name) {
      this.previewImage = name;
      this.showImgDialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.era-footer {
  background-image: linear-gradient(
    to bottom,
    rgba(232, 232, 232, 0),
    rgb(216, 249, 222)
  );
  padding: 1% 11%;

  .era-footer-container {
    // height: 2.84rem;
    // padding: 0.62rem 0 0;

    .flex-1 {
      .era-logo-footer {
        margin-bottom: 1.5vw;
      }

      .era-footer-phone,
      .era-footer-email {
        font-size: 1.1vw;
        font-weight: 400;
        line-height: 2.1vw;
      }
      .era-footer-icon {
        height: 1.5rem;
        width: 40%;
        font-size: 1.1vw;
        font-weight: 400;
        line-height: 2.1vw;
        display: flex;
        .icon-item {
          flex: 1;
          display: flex;
          flex-direction: column;
          .top {
            width: 1rem;
            flex: 1;
            padding: 5px 0px;
            .imgOne {
              margin: auto;
            }
            .imgOne:hover {
              cursor: pointer;
            }
          }
          .btn {
            flex: 2;
            .imgTwo {
              margin: auto;
              animation: myMou 1s;
              animation-fill-mode: forwards;
            }
            @keyframes myMou {
              0% {
                opacity: 0
              }
              100% {
                opacity: 1
              }
            }
          }
        }
      }
    }

    .era-footer-classify {
      font-size: 0.16rem;

      .era-footer-title {
        margin-bottom: 0.24rem;
        font-weight: 600;
        color: #000000;
      }

      .era-footer-content {
        transition: color 0.5s;
        -moz-transition: color 0.5s;
        -webkit-transition: color 0.5s;
        -o-transition: color 0.5s;
        cursor: pointer;
        margin-bottom: 0.16rem;
        font-weight: 400;
        color: #4d4d4d;
      }

      .era-footer-content:hover {
        color: #514ede;
      }

      .era-footer-img-box {
        width: 0.4rem;
        height: 0.4rem;
        margin-right: 0.2rem;

        .era-footer-img {
          position: relative;
          width: 1.01rem;
          height: 1.46rem;

          .img1,
          .img2,
          .img3 {
            transition: opacity 0.5s;
            -moz-transition: opacity 0.5s;
            -webkit-transition: opacity 0.5s;
            -o-transition: opacity 0.5s;
          }

          .img1 {
            opacity: 1;
          }

          .img2 {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
          }

          .img3 {
            cursor: pointer;
            opacity: 0;
            position: absolute;
            top: 0.45rem;
            left: -0.29rem;
          }
        }
      }

      .era-footer-img-box:hover {
        .era-footer-img {
          .img1 {
            opacity: 0;
          }

          .img2 {
            opacity: 1;
          }

          .img3 {
            opacity: 1;
          }
        }
      }
    }
  }

  .era-footer-others {
    padding-top: 1vw;
    padding-left: 35%;
    font-size: 0;

    .era-footer-rights,
    .era-footer-policy {
      font-size: 0.16rem;
      font-weight: 400;
      color: #666666;
    }

    .era-footer-rights {
      margin-top: 0.35rem;
    }

    .era-footer-policy {
      margin-top: 0.18rem;
    }
  }
}
</style>
